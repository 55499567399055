<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: userAccessMgmtUpdatePassword.vue
Description: This file contains form required to edit the user passoword through user access managament page
-->
<template>
  <div class="update-sub-user-password-details" ref="uam_update_password">
    <el-form
      label-position="left"
      label-width="30%"
      v-loading="loading"
      element-loading-background="white"
      :model="formData"
      size="mini"
      @submit.prevent="sumbitUpdatePasswordDetails"
    >
      <el-form-item
        prop="current_password"
        :label="userFormLabels[0].label"
        :error="
          ekmm__castErrorKeyToLang(ehm__errMessagesObject.current_password)
        "
      >
        <er-input
          v-model="formData.current_password"
          :placeholder="userFormLabels[0].placeholder"
          show-password
        >
        </er-input>
      </el-form-item>
      <el-form-item
        prop="new_password"
        :label="userFormLabels[1].label"
        :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.password)"
      >
        <er-input
          v-model="formData.password"
          :placeholder="userFormLabels[1].placeholder"
          show-password
          ref="passwordField"
          class="er-input--password"
          @input="pvm__getPercentage"
          :style="`--width:${pvm__passwordPercentage.width}px;--color:${pvm__passwordPercentage.color};--left:${pvm__passwordPercentage.left}px`"
        >
        </er-input>
      </el-form-item>
      <el-form-item
        prop="confirm_password"
        :label="userFormLabels[2].label"
        :error="
          ekmm__castErrorKeyToLang(ehm__errMessagesObject.confirm_password)
        "
      >
        <er-input
          v-model="formData.confirm_password"
          :placeholder="userFormLabels[2].placeholder"
          @keyup.native.enter="sumbitUpdatePasswordDetails"
          show-password
        >
        </er-input>
      </el-form-item>
      <el-row class="password-validations-container">
        <span class="header">
          {{ $t("Usrs_pwd_requirement_header") }}
        </span>
        <ul class="validation-rules">
          <li
            v-for="(validation, id) in pvm__passwordValidations"
            :key="id"
            :class="[
              'validation-rules-row',
              validation.validator() && 'is-checked',
            ]"
          >
            <div class="title">
              <p>{{ ftm__capitalize(validation.content) }}</p>
            </div>
          </li>
        </ul>
      </el-row>
      <layout-toolbar justify="end">
        <er-button
          size="mini"
          btnType="save"
          :showLabel="true"
          :showIcon="true"
          :disabled="isReadOnly"
          @click="sumbitUpdatePasswordDetails"
          :loading="loading"
        ></er-button>
        <er-button
          size="mini"
          btnType="cancel"
          :showLabel="true"
          @click="resetForm"
          :disabled="isReadOnly"
          :loading="loading"
        ></er-button>
      </layout-toolbar>
    </el-form>
  </div>
</template>
<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import passwordValidationMixin from "@/mixins/passwordValidationMixin";
import { UAM_UI_STATES } from "@/constants/uiStates";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [errorHandlerMixin, errorKeyMapMixin, passwordValidationMixin],
  inject: ["parentConfig"],
  data: function () {
    return {
      formData: {
        current_password: "",
        password: "",
        confirm_password: ""
      },
      ehm__errMessagesObject: {
        current_password: "",
        password: "",
        confirm_password: ""
      },
      userFormLabels: [],
      loading: false
    };
  },
  created() {
    //  this.formData.current_password = this.$store.getters['user/getUserProfile'].password
    this.userFormLabels = [
      {
        label: this.$t("Usrs_old_password"),
        placeholder: this.$t("Usrs_enter_old_pwd")
      },
      {
        label: this.$t("Comn_new_password"),
        placeholder: this.$t("Usrs_enter_new_pwd")
      },
      {
        label: this.$t("Comn_confirm_pwd"),
        placeholder: this.$t("Usrs_enter_confirm_pwd")
      }
    ];
  },
  mounted() {
    this.setRefsForPasswordValidations(
      this.$refs.uam_update_password,
      this.$refs.passwordField
    );
  },
  computed: {
    ...mapGetters("userAccessManagement", {
      getSidePanelState: "getSidePanelState"
    }),
    isReadOnly() {
      return this.$store.getters["user/isReadOnly"];
    },
    getUserData() {
      return this.getSidePanelState.userDetails;
    },
    getUAMTabData() {
      return this.parentConfig.uamTabData;
    },
    pvm__passwordValidations() {
      const password = this.formData.password || "";
      return this.pvm__passwordFieldValidations(password);
    }
  },
  methods: {
    ...mapActions("userAccessManagement", {
      restoreToIntialState: "restoreToIntialState",
      updatePasswordSubUser: "updatePasswordSubUser"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    async resetForm() {
      this.formData = {
        current_password: "",
        password: "",
        confirm_password: ""
      };
      this.ehm__errMessagesObject = {
        current_password: "",
        password: "",
        confirm_password: ""
      };
      await this.restoreToIntialState({
        shallFetchData: false,
        sidePanelUserId: this.getUserData._id,
        sidePanelUIState: UAM_UI_STATES.DISPLAY_SUB_USER_DETAILS
      });
    },
    async sumbitUpdatePasswordDetails() {
      this.loading = true;
      this.ehm__errMessagesObject = {
        current_password: "",
        password: "",
        confirm_password: ""
      };
      try {
        this.$gblUAMCanUserEdit(this.getUAMTabData);
        await this.updatePasswordSubUser({
          userId: this.getUserData._id,
          payload: this.formData
        });
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Usrs_pwd_upd_success"),
          duration: 5000,
          type: "success"
        });
        this.mixPanelEventGenerator({ eventName: "Settings - Users - Change Password - Save" });
        await this.restoreToIntialState({
          sidePanelUIState: UAM_UI_STATES.DISPLAY_SUB_USER_DETAILS
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss">
.update-sub-user-password-details {
  .el-form--label-left {
    padding: 20px 30px 30px 55px;
  }
  .el-form-item__label {
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }

  .er-input.er-input--password:after {
    content: "";
    position: absolute;
    width: var(--width);
    background-color: var(--color);
    height: 2px;
    bottom: 0px;
    left: var(--left);
    padding-left: 0px;
    transition: width 0.5s ease-out;
  }
}
</style>
