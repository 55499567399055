<template>
  <div>
    <el-row
      class="sub-user-details-form"
      ref="subUserDetailsForm"
    >
      <el-form
        label-position="left"
        :key="`${editType}_${getUserId}`"
        size="mini"
        :inline="true"
        :model="user"
        ref="form"
      >
        <el-row class="sub-user-type-selection">
          <el-form-item
            :label="userFormLabels[0].label"
            :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.user_type)"
          >
            <er-select
              v-model="user.user_type"
              :placeholder="userFormLabels[0].placeholder"
              @change="handleChangeInUserType"
            >
              <template v-for="(value, index) in this.availableUserTypes">
                <el-option
                  :key="index"
                  :label="$tc(getUserTypeLangStrs[value], 1)"
                  :value="value"
                ></el-option>
              </template>
            </er-select>
            <div class="sub-user-type-description" v-if="user.user_type">
              <span class="material-icons-outlined">info</span>
              <p>
                {{
                  $tc(
                    $gblUAMUsrRoleToUsrRoleDetailsObj[user.user_type]
                      .description,
                    1
                  )
                }}
              </p>
            </div>
          </el-form-item>
        </el-row>
        <el-row class="sub-user-basic-info">
          <layout-toolbar justify="start" class="sub-user-details-header">
            <span class="material-icons-outlined">info</span>
            <p class="sub-user-details-header__title">
              {{ $t("Usrs_necessary_info_update") }}
            </p>
          </layout-toolbar>
          <el-form-item
            prop="first_name"
            :label="userFormLabels[1].label"
            :error="
              aekmm__castErrorKeyToLang(ehm__errMessagesObject.first_name)
            "
          >
            <er-input
              v-model="user.first_name"
              class="input-box"
              :placeholder="userFormLabels[1].placeholder"
              :maxlength="userFieldLengths.first_name"
            ></er-input>
          </el-form-item>
          <el-form-item
            prop="last_name"
            :label="userFormLabels[2].label"
            :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.last_name)"
          >
            <er-input
              v-model="user.last_name"
              class="input-box"
              :placeholder="userFormLabels[2].placeholder"
              :maxlength="userFieldLengths.last_name"
            ></er-input>
          </el-form-item>
          <el-form-item
            prop="email"
            :label="userFormLabels[3].label"
            :error="getEmailFieldErrorMsgByEditType"
          >
            <el-input
              v-model="user.email"
              :disabled="editType === 'UPDATE'"
              class="user-phone"
              :placeholder="userFormLabels[3].placeholder"
              :maxlength="userFieldLengths.email"
            >
            <i slot="prefix" v-if="editType === 'UPDATE'" :title="getVerifyStylingObject('email').title" :class="`material-icons-outlined edit-icon ${getVerifyStylingObject('email').class}`">{{getVerifyStylingObject('email')["material-icon"]}}</i>
            <el-button slot="append" size="medium" :disabled="isReadOnly" v-if="editType === 'UPDATE'" @click="handleVerifyClick('Email')" :title="$t('Comn_edit')"> <i class="material-icons-round" >edit</i></el-button>
            </el-input>
          </el-form-item>
          <el-form-item
            prop="mobile"
            :label="userFormLabels[4].label"
            :error="getMobileFieldErrorMsgByEditType"
          >
            <el-input
              v-model="user.mobile"
              :disabled="editType === 'UPDATE'"
              class="user-phone"
              :placeholder="userFormLabels[4].placeholder"
              autocomplete="off"
              :maxlength="userFieldLengths.mobile"
            >
            <i slot="prefix" v-if="editType === 'UPDATE'" :title="getVerifyStylingObject('mobile').title" :class="`material-icons-outlined edit-icon ${getVerifyStylingObject('mobile').class}`">{{getVerifyStylingObject('mobile')["material-icon"]}}</i>
            <el-button slot="append" :disabled="isReadOnly" size="medium" v-if="editType === 'UPDATE'" @click="handleVerifyClick('Mobile')" :title="$t('Comn_edit')"> <i class="material-icons-round" >edit</i></el-button>
            </el-input>
          </el-form-item>
          <el-form-item label=" ">
            <el-tag
              v-if="this.editType === 'CREATE' && isEmailOrMobileEmpty"
              type="warning"
              size="small"
              >
              <i class="el-icon-warning-outline"></i>
                {{ getWarningByCountry }}
            </el-tag> &nbsp;
          </el-form-item>
        </el-row>
        <pageAccessComponent
          v-if="getUserTypeToPageAccessLayout(user.user_type).length"
          :layout="getUserTypeToPageAccessLayout(user.user_type)"
          :locations-list="locations"
          :permissions-list="$gblUAMPermissionsToPermissionDetailsList"
          :user-details="user"
          :editable="
            user.user_type === 'ACCOUNT_MANAGER_SUB_USER' ? false : true
          "
          @locations-changed="handleLocationsChange"
          @permissions-changed="handlePermissionsChange"
        >
        </pageAccessComponent>
      </el-form>
      <layout-toolbar
        type="flex"
        justify="end"
        class="btn-container right-panel-content-alignment"
      >
        <er-button
          size="mini"
          btnType="save"
          :showLabel="true"
          :showIcon="true"
          @click="submitForm"
          :loading="userDetailsSaveLoading"
        ></er-button>
        <er-button
          size="mini"
          btnType="cancel"
          :showLabel="true"
          @click="resetForm"
        ></er-button>
      </layout-toolbar>
    </el-row>
    <OTPVerificationDialog
      class="OTPVerificationDialog"
      :dialogVisible="dialogVisible"
      :dialogConfig="dialogConfig"
      :parentBtnLoading="btnLoading"
      :userData="user"
      :includeUserid="true"
      :ehm__errMessagesObjectCopy="ehm__errMessagesObject"
      @handleCloseDialog="handleCloseDialog"
      @submitUpdatedMobile="submitUpdatedMobile"
      @handleVerifyOtp="handleVerifyOtp"
    >
    </OTPVerificationDialog>
  </div>
</template>

<script>
import User from "@/model/user";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import authErrorKeyMapMixin from "@/mixins/authErrorKeyMapMixin";
import { mapGetters, mapActions } from "vuex";
import {
  userTypes,
  FTPermissionsToActions,
  groupPermissions,
  customerUserTypes,
} from "@/middleware/pageAccessManager";
import { UAM_UI_STATES } from "@/constants/uiStates";
import passwordValidationMixin from "@/mixins/passwordValidationMixin.vue";
import pageAccessComponent from "./pageAccessComponent";
import OTPVerificationDialog from "../../settings/OTPVerificationDialog.vue";
import fieldLengths from "../../../constants/fieldLengths";
import featureToggleMixin from "../../../mixins/featureToggleMixin";
export default {
  props: ["editType"],
  inject: ["parentConfig"],
  mixins: [errorHandlerMixin, authErrorKeyMapMixin, passwordValidationMixin, featureToggleMixin],
  components: { pageAccessComponent, OTPVerificationDialog },
  data() {
    return {
      userFieldLengths: fieldLengths,
      loading: false,
      user: new User(),
      ehm__errMessagesObject: new User(),
      FTPermissionsToActions,
      userTypeToPermissionLayout: {},
      createUserTypes: [
        userTypes.pond_owner,
        userTypes.pond_manager,
        userTypes.pond_worker,
        userTypes.pond_technician,
      ],
      editUserTypes: [
        userTypes.pond_owner,
        userTypes.pond_manager,
        userTypes.pond_worker,
        userTypes.pond_technician,
        userTypes.no_access,
      ],
      userPayloadSchema: [
        {
          prop: "first_name",
          user_type: customerUserTypes,
          action: ["CREATE", "UPDATE"],
        },
        {
          prop: "last_name",
          user_type: customerUserTypes,
          action: ["CREATE", "UPDATE"],
        },
        {
          prop: "email",
          user_type: customerUserTypes,
          action: ["CREATE", "UPDATE"],
        },
        {
          prop: "user_type",
          user_type: customerUserTypes,
          action: ["CREATE", "UPDATE"],
        },
        {
          prop: "allowed_locations",
          user_type: [
            userTypes.pond_manager,
            userTypes.pond_worker,
            userTypes.pond_technician,
          ],
          action: ["CREATE", "UPDATE"],
        },
        {
          prop: "permissions",
          user_type: [userTypes.pond_worker, userTypes.pond_technician],
          action: ["CREATE", "UPDATE"],
        },
      ],
      dialogVisible: false,
      fieldLengths: fieldLengths,
      oldMobile: "",
      oldEmail: "",
      dialogConfig: {
        title: "",
        oldData: "",
        name: "",
        maskValue: "",
        oldMobile: "",
        oldEmail: "",
      },
      btnLoading: false,
      userDetailsSaveLoading: false
    };
  },
  watch: {
    getUserId: function () {
      this.ehm__clearErrorMessages(new User());
      this.handleChangeInUser();
    },
  },
  computed: {
    ...mapGetters("userAccessManagement", {
      getSidePanelState: "getSidePanelState",
    }),
    ...mapGetters("user", {
      getUserLocations: "getUserLocations",
      getCurrUserLocation: "getCurrUserLocation",
      getUserProfile: "getUserProfile",
    }),
    isMobileLoginAllowed() {
      return this.ftgm__isUserHasFeature("allowed_mobile_login")
    },
    getWarningByCountry() {
      if (!this.isMobileLoginAllowed) {
        return this.$t('Comn_Email_and_Mobile_required');
      }
      return this.$t('Comn_Email_or_Mobile_require');
    },
    locations() {
      return this.$lodash.cloneDeep(this.getUserLocations);
    },
    locationIds() {
      return this.locations.map((location) => location._id);
    },
    userFormLabels() {
      return [
        {
          label: this.$t("Usrs_role_select"),
          placeholder: this.$t("Usrs_select_atleast_one_role"),
        },
        {
          label: `${this.$t("Usrs_first_name")}`,
          placeholder: this.$t("Usrs_enter_first_name"),
        },
        {
          label: `${this.$t("Usrs_last_name")}`,
          placeholder: this.$t("Usrs_enter_last_name"),
        },
        {
          label: this.$t("Comn_email_address"),
          placeholder: this.$t("Usrs_enter_email_addr"),
        },
        {
          label: this.$t("Comn_mobile_num"),
          placeholder: this.$t("Usrs_enter_mobile_num"),
        },
        {
          label: this.$t("Usrs_pwd"),
          placeholder: this.$t("Usrs_enter_pwd"),
        },
        {
          label: this.$t("Comn_confirm_pwd"),
          placeholder: this.$t("Usrs_enter_confirm_pwd"),
        },
      ];
    },
    availableUserTypes() {
      return this.editType === "UPDATE"
        ? this.editUserTypes
        : this.createUserTypes;
    },
    getUserTypeLangStrs() {
      return this.parentConfig.USER_TYPE;
    },
    getUserData() {
      return this.getSidePanelState.userDetails;
    },
    computedUserProfile() {
      return {
        ...this.getUserData,
        is_email_empty: !this.getUserData.email,
        is_mobile_empty: !this.getUserData.mobile && !this.getUserData.phone,
      }
    },
    getUserId() {
      return this.getUserData._id;
    },
    getUAMTabData() {
      return this.parentConfig.uamTabData;
    },
    isEmailOrMobileEmpty: function() {
      return (this.ehm__errorCode) && (this.user.email === "" && this.user.mobile === "");
    },
    getEmailFieldErrorMsgByEditType() {
      return this.editType !== "UPDATE"
        ? this.aekmm__castErrorKeyToLang(this.ehm__errMessagesObject.email)
        : "";
    },
    getMobileFieldErrorMsgByEditType() {
      return this.editType !== "UPDATE"
        ? this.aekmm__castErrorKeyToLang(this.ehm__errMessagesObject.mobile)
        : "";
    },
    aekmm__keyToInvalidTranslations: function() {
      return {
        first_name: {
          INVALID_VALUE: "Usrs_first_name_wrng_format",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "min_3_char_for_field",
          VALUE_HAVE_MAX_CHAR: "max_24_char_for_field"
        },
        last_name: {
          INVALID_VALUE: "Usrs_first_name_wrng_format",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "min_3_char_for_field",
          VALUE_HAVE_MAX_CHAR: "max_24_char_for_field"
        },
        email: {
          INVALID_VALUE: "email_in_valid_format",
          INVALID_USER_DATA: "Usrs_email_already_exists",
          VALUE_HAVE_MAX_CHAR: "max_254_char_for_field",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "min_char_for_field"
        },
        login_id: {
          INVALID_VALUE: "Usrs_email_in_valid_format",
          INVALID_USER_DATA: "Usrs_email_already_exists",
          VALUE_HAVE_MAX_CHAR: "Usrs_email_in_valid_format",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "Usrs_email_in_valid_format"
        },
        password: {
          INVALID_VALUE: "Usrs_pwd_wrng_frmt",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "Usrs_pwd_wrng_frmt",
          INVALID_PASSWORD: "Usrs_pwd_wrng_frmt"
        },
        confirm_password: {
          INVALID_VALUE: "Usrs_pwd_cnpwd_valida",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "Usrs_pwd_wrng_frmt",
          VALUES_SHOULD_MATCH:
            "both_the_password_and_confirm_password_must_match",
          PASSWORD_CONFIRM_PASSWORD_NOT_SAME:
            "both_the_password_and_confirm_password_must_match"
        },
        country: { INVALID_VALUE: "Comn_country" },
        phone: { INVALID_VALUE: "Comn_mobile_num_valid" },
        mobile: { INVALID_VALUE: (this.isMobileLoginAllowed ? "Comn_mobile_num_valid" : "Comn_phone_num_valid") },
        timezone: { INVALID_VALUE: "Comn_timezone_not_valid" },
        otp: {
          VALUE_DO_NOT_HAVE_MIN_CHAR: "comn_otp_error_msg",
          INVALID_VALUE: "invalid_OTP"
        }
      };
    }
  },
  created() {
    this.handleChangeInUser();
  },
  mounted() {
    this.setRefsForPasswordValidations(
      this.$refs.subUserDetailsForm.$el,
      this.$refs.passwordField
    );
  },
  methods: {
    ...mapActions("userAccessManagement", {
      restoreToIntialState: "restoreToIntialState",
      updateSubUser: "updateSubUser",
      createSubUser: "createSubUser",
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator",
    }),
    getVerifyStylingObject(communication_channel) {
      const attributeToCheckEmpty = communication_channel === "email" ? "is_email_empty" : "is_mobile_empty";
      if (this.computedUserProfile[attributeToCheckEmpty] || ((communication_channel === "mobile") && (!this.ftgm__isUserHasFeature("allowed_mobile_login")))) {
        return { class: "", "material-icon": '\u2800\u2800', title: '' };
      }
      const attributeToVerify = communication_channel === "email" ? "email_verified" : "mobile_verified";
      if (this.computedUserProfile[attributeToVerify]) {
        return { class: "user-profile-verified", "material-icon": 'verified', title: this.$t('comn_verified') }
      }
      return { class: "user-profile-not-verified", "material-icon": 'new_releases', title: this.$t('comn_not_verified') };
    },
    handleVerifyClick(data) {
      try {
        this.$gblUAMCanUserEdit(this.getUAMTabData);
        this.oldMobile = this.user.mobile || "";
        this.oldEmail = this.user.email || "";
        this.dialogVisible = true;
        this.dialogConfig.name = data;
        this.dialogConfig.oldMobile = this.oldMobile;
        this.dialogConfig.oldEmail = this.oldEmail;
        this.dialogConfig.title =
          data === "Mobile"
            ? this.$t("update_your_mobile")
            : this.$t("update_your_email");
        this.dialogConfig.oldData =
          data === "Mobile"
            ? this.$t("comn_old_mobile")
            : this.$t("comn_old_email");
        this.dialogConfig.maskValue =
          data === "Mobile" ? this.oldMobile : this.oldEmail;
        this.dialogConfig.is_verified =
          data === "Mobile"
            ? this.computedUserProfile.mobile_verified
            : this.computedUserProfile.email_verified;
        this.dialogConfig.is_empty =
          data === "Mobile"
            ? this.computedUserProfile.is_mobile_empty
            : this.computedUserProfile.is_email_empty;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    handleCloseDialog() {
      this.dialogVisible = false;
    },
    async handleVerifyOtp(data) {
      await this.handleCloseDialog();
      this.$emit("statusChange", {
        status: "SUCCESS",
        userId: this.user._id,
      });
    },
    async submitUpdatedMobile(userData) {
      try {
        this.btnLoading = true;
        this.$gblUAMCanUserEdit(this.getUAMTabData);
        this.validateForPermissionsAndLocations();
        delete userData.phone;
        this.ehm__clearErrorMessages(new User());
        const response = await this.updateSubUser({
          userId: userData._id,
          payload: {
            ...this.getPayload(userData),
            email: undefined, // to prevent sending email field which throws Unknown error
            mobile: userData.mobile
          },
        });
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Usrs_usr_details_upd_successfully"),
          duration: 5000,
          type: "success",
        });
        this.handleCloseDialog();
        this.$emit("statusChange", {
          status: "SUCCESS",
          userId: this.editType === "CREATE" ? response._id : userData._id,
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.btnLoading = false;
      }
    },
    getPayload(user) {
      return (
        this.editType === "UPDATE"
          ? this.userPayloadSchema
          : [
              {
                prop: "mobile",
                user_type: customerUserTypes,
                action: ["CREATE", "UPDATE"],
              },
              ...this.userPayloadSchema,
            ]
      ).reduce((userObj, currProp) => {
        const conditions = [
          currProp.action.includes(this.editType) &&
            currProp.user_type.includes(user.user_type),
        ];
        if (conditions.some((x) => x)) {
          if (
            this.user[currProp.prop] !== "" &&
            this.user[currProp.prop] !== undefined
          ) {
            userObj[currProp.prop] = this.user[currProp.prop];
          }
        }
        return userObj;
      }, {});
    },
    getUserTypeToPageAccessLayout(userType) {
      switch (userType) {
        case userTypes.pond_owner:
        case userTypes.admin:
        case userTypes.no_access:
        case userTypes.skretting_technician:
          return [];
        case userTypes.pond_manager:
          return ["locations"];
        default:
          return ["permissions", "locations"];
      }
    },
    handleChangeInUserType() {
      const isLocationsEmpty = this.user.allowed_locations.length === 0;
      if (isLocationsEmpty) {
        this.user.allowed_locations = [this.getCurrUserLocation._id];
      }
      const doesUserTypeHasPermissions = [
        [userTypes.pond_worker, userTypes.pond_technician].includes(
          this.user.user_type
        ),
      ].every((x) => x);
      if (doesUserTypeHasPermissions) {
        this.user.permissions = this.$gblUAMGetDefaultPermissions[this.user.user_type];
        this.user.parsedPermissions = Object.assign(
          this.user.parsedPermissions || {},
          groupPermissions(this.user.permissions)
        );
      }
    },
    ehm__error403Handler(err) {
      const errorData = err.response.data;
      let message = "";
      if (errorData.error_code === "ACCOUNT_ALREADY_TAKEN") {
        if (this.editType === "CREATE") {
          message =
            errorData.error_type === "Mobile"
              ? this.$t('Comn_mobile_num_exist')
              : this.$t('Comn_email_exist')
        } else {
          message =
            errorData.error_type === "Mobile"
              ? this.$t("Comn_mobile_num_exist")
              : this.$t("Comn_email_exist");
        }
      }
      this.$notify({
        title: this.$t("failed"),
        message: message,
        type: "error",
      });
    },
    handleChangeInUser() {
      this.user = this.$lodash.cloneDeep(this.getUserData);
      this.user.mobile = this.user.mobile || this.user.phone;
      if (this.editType === "CREATE") {
        this.user.user_type = userTypes.pond_owner;
      }
    },
    handleLocationsChange(changedLocations) {
      this.user.allowed_locations = changedLocations;
    },
    handlePermissionsChange(changedPermissions, parsedPermissions) {
      this.user.permissions = changedPermissions;
      this.user.parsedPermissions = Object.assign(
        this.user.parsedPermissions || {},
        groupPermissions(this.user.permissions)
      );
    },
    async resetForm() {
      this.user = new User();
      this.user.allowed_locations = [this.locationIds[0]];
      await this.restoreToIntialState({
        shallFetchData: false,
        sidePanelUserId: this.getUserData._id,
        sidePanelUIState: UAM_UI_STATES.DISPLAY_SUB_USER_DETAILS,
      });
    },
    ehm__error409Handler: function (err) {
      if (err.response.data.errors != null) {
        const errorDetails = err.response.data.errors.details;
        errorDetails.forEach((el, index) => {
          this.ehm__unhandledErrorMessage +=
            (index === 0 ? "" : ",") +
            el.message +
            (errorDetails.length - 1 === index ? "" : ",");
        });
      } else {
        err.response.data.key = "email";
        this.ehm__errMessagesObject.email = err.response.data;
      }
    },
    validateForPermissionsAndLocations() {
      const errors = [
        {
          field: this.$t("Comn_permissions"),
          value: this.user.permissions,
          key: "permissions",
        },
        {
          field: this.ftm__capitalize(this.$tc("Comn_location.case.lower", 2)),
          value: this.user.allowed_locations,
          key: "locations",
        },
      ].filter((property) => {
          return this.getUserTypeToPageAccessLayout(
            this.user.user_type
          ).includes(property.key);
        }).reduce((acc, property) => {
          if (property.value.length === 0) {
            acc.push({
              message: this.$t("Comn_must_not_be_empty", {
                field: property.field,
              }),
            });
          }
          return acc;
        }, []);
      if (errors.length > 0) {
        throw {
          type: "FAIL_TO_SAVE",
          errors,
        };
      }
    },
    hasAnyViewAccess(permissions) {
      for (const module in permissions) {
        if (permissions[module].VIEW) {
          return true;
        }
      }
      return false;
    },
    async submitForm() {
      try {
        this.$gblUAMCanUserEdit(this.getUAMTabData);
        this.validateForPermissionsAndLocations();
        this.userDetailsSaveLoading = true
        this.user.first_name = this.upm__trimSpacesInStr(this.user.first_name);
        this.user.last_name = this.upm__trimSpacesInStr(this.user.last_name);
        this.user.mobile = this.upm__trimSpacesInStr(this.user.mobile);
        this.user.email = this.upm__trimSpacesInStr(this.user.email);
        const modeToStoreFunc = {
          CREATE: {
            func: "createSubUser",
            params: this.getPayload(this.user),
            success_message: "Usrs_sub_usr_creat_succe",
          },
          UPDATE: {
            func: "updateSubUser",
            params: {
              userId: this.user._id,
              payload: this.getPayload(this.user),
            },
            success_message: "Usrs_usr_details_upd_successfully",
          },
        };
        this.ehm__clearErrorMessages(new User());
        const response = await this[modeToStoreFunc[this.editType].func](
          modeToStoreFunc[this.editType].params
        );
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t(modeToStoreFunc[this.editType].success_message),
          duration: 5000,
          type: "success",
        });
        if (this.editType === "CREATE") {
          this.mixPanelEventGenerator({
            eventName: "Settings - Users - Add User - Save",
          });
        } else {
          this.mixPanelEventGenerator({
            eventName: "Settings - Users - Edit User - Save",
          });
        }
        this.$emit("statusChange", {
          status: "SUCCESS",
          userId: this.editType === "CREATE" ? response._id : this.user._id,
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
        if (err.type === "FAIL_TO_SAVE") return;
        this.$emit("statusChange", { status: "FAILED" });
      } finally {
        this.userDetailsSaveLoading = false
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_passwordvalidation.scss";

.sub-user-details-form {
  @include password-validation-mixin;

  &::v-deep .el-form-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    .el-form-item__label {
      @include responsiveProperty(width, 110px, 130px, 160px);
      @include small-text;
    }

    .el-form-item__content {
      @include responsiveProperty(width, 57%, 50%, 45%);

      .el-input,
      .el-input .el-input__inner,
      .el-select {
        @include small-text;
        width: 100%;
      }
    }
  }

  &::v-deep .er-input.er-input--password:after {
    content: "";
    position: absolute;
    width: var(--width);
    background-color: var(--color);
    height: 2px;
    bottom: 0px;
    left: var(--left);
    padding-left: 0px;
    transition: width 0.5s ease-out;
  }

  &::v-deep .sub-user-details-header {
    padding: 5px 15px;
    font-weight: 700;
    @include normal-text;
  }

  &::v-deep .sub-user-type-selection {
    padding-top: 25px;

    .el-form-item .el-form-item__content {
      @include responsiveProperty(width, 57%, 50%, 45%);
    }

    .sub-user-type-description {
      display: inline-flex;
      flex-direction: row;
      @include v-small-text;
      white-space: break-word;
      line-height: 1;
      margin-top: 10px;

      & > * {
        align-self: center;
      }

      .material-icons-outlined {
        margin-right: 5px;
        @include small-text;
      }
    }
  }
}
</style>
<style lang="scss">
.sub-user-basic-info {
  .user-phone {
    .el-input-group__append {
      color: #000000;
      padding: 0px 5px;
    }

    .material-icons-round {
      font-size: 18px;
      cursor: pointer;
    }
  }
  .edit-icon{
    font-size: 16px;
  }
  .el-button:not(.is-disabled) .edit-icon {
    // font-size: 16px;
    color: black;
  }

  .user-profile-verified {
    font-size: 14px;
    color: #57e657;
    margin-right: 5px;
    padding: 7px 0px;
  }

  .user-profile-not-verified {
    font-size: 14px;
    color: #ed6c41;
    margin-right: 5px;
    padding: 7px 0px;
  }
}
</style>
